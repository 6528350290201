.bar-chart-resp {
    width: 90%;
    height: 500px;
}

.bar-chart-resp2 {
    width: 60%;
    height: 500px;
}

table td {
    border-top: 0 !important;
}

.section-column {
    width: 22%;
}

@media (min-width: 511px) and (max-width: 920px) {
    .bar-chart-resp, .bar-chart-resp2 {
        width: 100% !important;
        height: 100% !important;
    }
}

@media (min-width: 210px) and (max-width: 850px) {
    .mobile-column-resp {
        flex-direction: column !important;
    }
}

@media (min-width: 210px) and (max-width: 510px) {
    .bar-chart-resp, .bar-chart-resp2 {
        width: 100% !important;
        height: 100% !important;
    }
}

@media (max-width: 1200px) {
    .section-column {
        width: 28%;
    }
} 

@media (max-width: 992px) {
    .section-column {
        width: 30%;
    }
} 

@media (max-width: 768px) {
    .section-column {
        width: 50%;
    }
} 

@media (max-width: 500px) {
    .section-column {
        width: 100%;
    }
} 